// WordPress Defaults
.alignnone {
    margin: 0.5em 0;
}

.aligncenter {
    display: block;
    margin: 15px auto;
}

.alignright {
    float: right;
    margin: 0.5em 0 0.5em 1em;
    max-width: 40%;
}

.alignleft {
    float: left;
    margin: 0.5em 1em 0.5em 0;
    max-width: 40%;
}
@include breakpoint(small) {
    .alignright, .alignleft { max-width: 50%; }
}

.wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%;
    padding: 5px 3px 0px;
    text-align: center;
    margin: 0;
}

.wp-caption.alignnone {
    margin: 0.5em 0;
}

.wp-caption.alignleft {
    margin: 0.5em 1em 0.5em 0;
}

.wp-caption.alignright {
    margin: 0.5em 0 0.5em 1em;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 1.5em;
    margin: 0;
    padding: 5px;
}

/* iOS does not obey max-width if width is set. */
.ios .wp-caption {
    width: auto !important;
}
.wp-caption-dd {
    font-size: 14px;
    padding-top: 0.5em;
    margin: 0; /* browser reset */
}

img.wp-smiley {
    vertical-align: middle;
}
.screen-reader-text { 
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.gallery {
    margin: auto -5px;
    display: flex;
    flex-wrap: wrap;
}
.gallery-item {
    flex: 0 1 auto;
    margin: 0 !important;
    padding: 5px;
    text-align: center;
    width: 50%;
    
    @include breakpoint(small) { width: 33.3333%; }   
    
    .gallery-columns-1 & { width: 100%; }
    .gallery-columns-2 & { width: 50%; }
    
    br { display: none; }

    @include breakpoint(medium) {
        width: 25%;
        .gallery-columns-3 & { width: 33.3333%; }
        .gallery-columns-4 & { width: 25%; }
    }

    @include breakpoint(large) {
        br { display: block; }
        .gallery-columns-5 & { width: 20%; }
        .gallery-columns-6 & { width: 16.6666%; }
        .gallery-columns-7 & { width: 14.2857%; }
        .gallery-columns-8 & { width: 12.5%; }
        .gallery-columns-9 & { width: 11.1111%; }
    }
}
.gallery img {
    width: 100%;
    border: 1px solid #333;
    box-shadow: 0 0 4px #ccc;
}
.gallery-caption {
    margin: 0;
    padding-top: 5px;
    font-size: 0.75em;
    opacity: 0.75;    
}
