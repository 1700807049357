//
// Layout and Grid
// ==========================================================================

// To establish width of "wrap" class (see grid.scss)
$content-width: 1200px;

// For Media Queries 
$xlarge: 1200px;
$large: 1000px;  
$medium: 768px;  
$small: 480px;   
$xtra-small: 320px; 

//
// Fonts
// ==========================================================================

$base-font: "Arvo", sans-serif;
$fancy-font: "Arvo", sans-serif;

// Font sizes
$xsm-fz: 12px;
$sm-fz: 14px;
$md-fz: 16px;
$lg-fz: 24px;
$xlg-fz: 36px;
$xxlg-fz: 45px;

//
// Spacings
// ==========================================================================

$xsm-sp: 10px;
$sm-sp: 20px;
$md-sp: 40px;
$lg-sp: 80px;
$xlg-sp: 110px;

$letter-sp: .075em;
$sm-letter-sp: .05em;
$xsm-letter-sp: .012em;

$line-height: 1.2em;

// typographic spacings

$header-margin-bottom: $md-sp;
$paragraph-margin-bottom: $md-sp;

//
// Colors
// ==========================================================================

$primary: #888;
$secondary: #aaa;
$tertiary: #ccc;

$light: #fff;
$dark: #000;

//
// Mixins
// ==========================================================================

// Media Query mixin
@mixin breakpoint($viewport, $width: false) {
  @if $viewport == xtra-small {
      @media only screen and (min-width: $xtra-small) { @content; }
  }
  @else if $viewport == small {
      @media only screen and (min-width: $small) { @content; }
  }
  @else if $viewport == medium {
      @media only screen and (min-width: $medium) { @content; }
  }
  @else if $viewport == large {
      @media only screen and (min-width: $large) { @content; }
  }
  @else if $viewport == large-max {
      @media only screen and (max-width: $large) { @content; }
  }
  @else if $viewport == xlarge {
      @media only screen and (min-width: $xlarge) { @content; }
  }
  @else if $width == min {
      @media only screen and (min-width: $viewport) { @content; }
  }
  @else if $width == max {
      @media only screen and (max-width: $viewport) { @content; }
  }
}

// Micro Clearfix Hack - http://nicolasgallagher.com/micro-clearfix-hack/

@mixin clearfix-micro() {
  & {
    *zoom: 1;
  }
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin easy-hover($color: $secondary, $duration: 0.25s) {
  transition: all $duration;

  &:hover {
    background: $color;
    @content;
  }
}