//Setup colors, variables, and mixins
@import "../partials/variables-mixins";
//col-x-x grid stuff
@import "../partials/grid";

// Flickity styles.  Have to import this early to prevent FOUT.
@import "../partials/flickity";

//
//   Sticky footer styles 
// ==========================================================================

html, 
#page {
  height: 100%;
}

#page {
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1 0 auto;
  width: 100%;
}

.site-header,
.colophon {
  flex: none;
}

//
//   Header 
// ==========================================================================

.header-container {
  position: relative;
}

h1.logo-header-tag {
  margin: 0 20px;
}

// Admin bar w/ fixed header 

.admin-bar .navbar,
.admin-bar .menu-btn {
  top: 46px;

  @include breakpoint(medium) {
    top: 32px;
  }

  @include breakpoint(600px, max) {
    position: absolute;
  }
}

// Fixed Header padding

body {
  padding-top: 60px;

  @include breakpoint(large) {
    padding-top: 80px;
  }
}


//
// Menus
// ==========================================================================

body.menu-active {
  overflow:hidden;
}

.menu-btn {
  display: block;
  width: 60px;
  height: 60px;
  background: $secondary;
  border: solid 4px rgba($secondary, 0.4);
  position: fixed;
  top: 0em;
  right: 0em;
  transition: all 0.25s;
  padding: 0;
  position: fixed;
  z-index: 200;

  .bar {
    display: block;
    height: 3px;
    width: 80%;
    background: $primary;
    margin: 10px auto;
    transition: all 0.5s;
  }

  &.active {
    background: $primary;
    border: solid 4px rgba($secondary, 0);

    .bar {
      background: $light;
      &.top {
        transform: translateY(12px) rotateZ(45deg);
      }

      &.middle {
        width: 0;
      }

      &.bottom {
        transform: translateY(-14px) rotateZ(-45deg);
      }
    }
  }

  @include breakpoint(large) {
    display: none;
  }
} // .menu-btn

.logo.nav-logo{
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo-img{
    max-height: 100%;
    height: 60px;
  }

  @include breakpoint(large) {
    height: 80px;

    .logo-img{
      height: 80px;
    }
  }
}

.nav-overlay {
  display: none;
  width: 100%;
  height: 100%;
  background: rgba($dark, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;

  @include breakpoint(large) {
    display: none !important;
  }
}

.navbar {
  height: 60px;
  width: 100%;
  background: $primary;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 95;
  box-shadow: 0px 1px 5px #444;

  & > .flex { align-items: center; }

  .search-form { display: flex; }

  @include breakpoint(large) {
    height: 80px;
  } // large bp
} // .navbar

nav.main-nav {
  width: 100%;
  display: block;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: -120%;
  background: $primary;
  z-index: 100;
  box-shadow: 2px 0px 10px #444;
  transition: all 0.5s;

  &.active {
    right: 0;
  }

  @include breakpoint(small) {
    max-width: 400px;
  }

  @include breakpoint(large) {
    position: static;
    max-width: 100%;
    height: 80px;
    box-shadow: none;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    overflow: visible;
  } // large bp
} // nav.main-nav

nav.main-nav ul {
  margin: 0;
  padding: 0;
  width: 100%;
  padding-top: 80px;

  & > li.menu-item {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    a {
      text-decoration: none;
      color: $light;
      text-align: center;
      display: block;
      padding: 20px;
      font-size: 130%;
      font-weight: bold;
      width: 100%;
    }

    &.menu-item-has-children {
      position: relative;

      a.sub-menu-btn {
        padding: 0;
        line-height: 30px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: rgba($light, 0.2);
        font-size: 200%;
        position: absolute;
        top: 15px;
        right: 20px;
        border-radius: 100%;
        transition: all 0.5s;

        &.active {
          transform: rotate(135deg);
          color: $secondary;
          background: rgba($light, 0.9);
        }
      }
    }
  } // & > li.menu-item

  @include breakpoint($large, max) {
    ul.sub-menu {
      display: block;
      max-height: 0;
      transition: max-height 0.5s;
      overflow: hidden;
      padding-top: 0;
      background: $secondary;

      &.active-sub-menu {
        display: block;
        padding: 0;
        max-height: 500px;
      }

      ul.sub-menu {
        background: $tertiary;

        li {
          background: $tertiary;
        }
      }
    }
  } // large max bp

  @include breakpoint(large) {
    padding-top: 0;
    display: flex;
    width: 100%;
    height: 80px;

    & > li.menu-item {
      height: 100%;
      position: relative;
      transition: all 0.25s;

      a {
        font-size: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include easy-hover($tertiary);
      }

      &.menu-item-has-children {
        position: relative;

        a.sub-menu-btn {
          display: none;
        }
      }

      & > ul.sub-menu,
      ul.sub-menu.active-sub-menu {
        display: none;

        ul.sub-menu.active-sub-menu {
          display: none;
        }
      }

      &:hover {
        background: lighten($primary, 20%);

        & > ul.sub-menu,
        ul.sub-menu.active-sub-menu {
          overflow: visible;
          max-height: none;
          display: block;
          position: absolute;
          top: 80px;
          left: 0;
          box-shadow: 0px 1px 5px #444;
          min-width: 400px;
          height: auto;
          padding: 0;
          z-index: -1;
          transition: all 0.5s;
          border-left: solid 5px $tertiary;

          & > li.menu-item {
            display: block;
            width: 100%;
            position: relative;
            background: $secondary;

            &:hover {
              background: lighten($secondary, 5%);

              & > ul.sub-menu {
                position: absolute;
                display: block;
                top: 0;
                left: 100%;
                z-index: -1;
              }
            }
          }
        }
      } // &:hover
    } // & > li.menu-item
  } // large bp
} // nav.main-nav ul

//
// Front Page
// ==========================================================================


//
// SlideShow
// ==========================================================================

.flickity-viewport {
  transition: height 0.2s;
  background: $secondary;
}

.flickity-page-dots .dot {
  width: 12px;
  opacity: 1;
  height: 12px;
  background: transparent;
  border: 2px solid $primary;
  transition: all 0.2s;

  &.is-selected {
    background: $primary;
  }
}

#slideshow {
  width: 100%;
  margin: 20px auto;

  .slide {
    width: 100%;
    display: block;

    a {
      text-decoration: none;
    }
  }

  .caption-contain {
    width: 100%;
    background: $secondary;
    color: $light;
    padding: 15px;

    h1 {
      font-size: 1.4em;
    }
  }

  .img-contain {
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  @include breakpoint(large) {
    width: 100%;
    margin: 20px 0;

    .caption-contain {
      position: absolute;
      bottom: 20px;
      left: 100px;
      max-width: 400px;
      z-index: 20;
      box-shadow: 0px 1px 5px #444;

      h1 { font-size: 2em; }
    }
  }
} // #slideshow

.cta {
  a { text-decoration: none; }
}
