//Include normalize.cs
@import "partials/normalize";
//Setup colors, variables, and mixins
@import "partials/variables-mixins";
//col-x-x grid stuff
@import "partials/grid";
//baseline styles and defaults
@import "partials/global";

//critical css
@import "inline/inline.scss";

@import "inline/home.scss";

@import "inline/blog.scss";


//
// Buttons, Readmores, Misc
// ==========================================================================

.readmore {
  color: #006;
  font: 14px/18px $fancy-font;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
}

.btn {
  display: inline-block;
  padding: 6px 12px 4px;
  border: solid 2px #000;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  color: #fff;
  background: rgba(87, 104, 112, 0.85);
  text-decoration: none;
}

//
// Pages
// ==========================================================================

.entry-title { margin-top: 0; }

//
// Front Page
// ==========================================================================

.ctas {
  margin-top: 20px;
  margin-bottom: 20px;
}

//
// Single posts
// ==========================================================================

//
// Footer Styles
// ==========================================================================

.colophon {
  background: $secondary;

  .footer-logo-wrap {
    max-width: 200px;
  }
}

@import "partials/wordpress-defaults";
@import "partials/helpers-placeholders";
@import "partials/print";