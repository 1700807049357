// ******************
// Grid and Layout
// ******************

$pad: 10px; // Gutter size

.wrap,
.full-width {
	@include clearfix-micro;
}

.wrap {
	margin: 0 auto;
  padding: 0 $pad;
	width: 100%;
	max-width: $content-width !important;
}

.full-width {
	margin: 0 $pad; // Add margin so non-column elements line up with ".col-" elements
	width: 100%;
	clear: both;
}

.flex, 
.flex-full-width {
  // makes element a flexbox
  display: flex;
	flex-wrap: wrap;
	width: 100%;
  max-height: 100%;
	padding: 0;
  margin: 0 auto;

  &.center {
    // center content X & Y
    justify-content: center;
    align-items: center;
  }

  &.center-x {
    // Center elements horizontally
    justify-content: center;
  }

  &.center-y {
    // Center elements Vertically
    align-items: center;
  }

  &.end {
    // Aligns to bottom right corner
    justify-content: flex-end;
    align-items: flex-end;
  }

  &.end-x {
    // Aligns to the right
    justify-content: flex-end;
  }

  &.end-y {
    // Aligns to the bottom
    align-items: flex-end;
  }

	&.nowrap{
		//Elements will not wrap
		flex-wrap: nowrap;
	}

	&.no-auto-height{
		// Removes height based off siblings
		align-items: flex-start;
	}

	&.reverse{
		//reverses child order
		flex-direction: row-reverse;
	}

	&.column{
		//column rather than row
		flex-direction: column;
	}
}

.flex {
  max-width: $content-width !important; 
}

[class*='col-']  // Target all elements that start with "col-"
 {
  padding: $pad; // Add padding to visually separate columns


  &.first {
    padding: $pad;

    @include breakpoint(medium) {
      padding-left: 0;
    }
  }

  &.last {
    padding: $pad;

    @include breakpoint(medium) {
      padding-right: 0;
    }
  }
}

.col-1-2 {
  width: 100%;

  @include breakpoint(medium) {
    width: 50%;
  }
}

.col-1-3 {
  width: 100%;

  @include breakpoint(medium) {
    width: 33.33%;
  }
}

.col-2-3 {
  width: 100%;

  @include breakpoint(medium) {
    width: 66.66%;
  }
}

.col-1-4 {
  width: 100%;

  @include breakpoint(medium) {
    width: 25%;
  }
}

.col-3-4 {
  width: 100%;

  @include breakpoint(medium) {
    width: 75%;
  }
}

.col-1-8 {
  width: 100%;

  @include breakpoint(medium) {
    width: 12.5%;
  }
}

.col-2-8 {
  width: 100%;

  @include breakpoint(medium) {
    width: 25%;
  }
}

.col-3-8 {
  width: 100%;

  @include breakpoint(medium) {
    width: 37.5%;
  }
}

.col-4-8 {
  width: 100%;

  @include breakpoint(medium) {
    width: 50%;
  }
}

.col-5-8 {
  width: 100%;

  @include breakpoint(medium) {
    width: 62.5%;
  }
}

.col-6-8 {
  width: 100%;

  @include breakpoint(medium) {
    width: 75%;
  }
}

.col-7-8 {
  width: 100%;

  @include breakpoint(medium) {
    width: 87.5%;
  }
}
